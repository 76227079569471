<template>
  <div class="p-6 rounded-xl bg-white">
    <div class="flex justify-between items-center mb-6">
      <h2 class="text-[18px] leading-[150%]">Overall Rating</h2>
      <Edit class="cursor-pointer" @click="isEdit = !isEdit" v-tooltip.top="'Click to edit rating'" />
    </div>
    <div class="flex justify-between items-center bg-[#FAFAFA] p-5 rounded-3xl py-5 mb-6">
      <star-rating
        v-model:rating="rating.no"
        :starSize="35"
        :disableClick="!isEdit"
        starColor="#FDD836"
        inactiveColor="#D8D8D8"
        :increment="0.5"
        :show-rating="false"
      />
      <span class="inline-flex border w-[33px] h-[33px] items-center justify-center text-[14px] font-bold border-light-grey rounded-md">{{
        rating.no
      }}</span>
    </div>
    <div v-if="isEdit">
      <div class="w-full rounded-xl border mb-4">
        <QuillEditor v-model:content="rating.comment" contentType="html" class="bg-white" theme="snow" toolbar="minimal" />
      </div>
      <div class="text-right">
        <button class="bg-brand-black text-white text-[16px] px-10 h-[56px] rounded-xl" @click="addEvaluation">Save</button>
      </div>
    </div>
    <div class="flex items-start mb-8" v-else>
      <img :src="avatar ? avatar : userImg" alt="image" class="w-[40px] h-[40px] rounded-full mr-4 mt-1" />
      <div>
        <div class="flex items-center space-x-2">
          <h2 class="font-manrope font-bold leading-[160%]">
            {{ application?.job?.postedBy?.name }}
          </h2>
          <p class="text-[#ADADAD] text-[10px]">added a comment</p>
        </div>
        <p class="text-light-gray-200 text-[14px] leading-[170%] font-manrope" v-html="rating.comment"></p>
        <p class="text-[#ADADAD] text-[12px]">{{ moment(application?.updated_at).format('DD/MM/yyyy') }}</p>
      </div>
    </div>
  </div>

  <loading-overlay v-if="loading" />
</template>

<script setup>
import { ref, onBeforeUpdate, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { errorMessage } from '@/utils/helper';
import { useToast } from 'vue-toastification';
import LoadingOverlay from '../LoadingOverlay.vue';
import userImg from '../../assets/images/placeholder.png';
import moment from 'moment';
import StarRating from 'vue-star-rating';
import { useRoute } from 'vue-router';
import { Edit } from '@/icons';

const props = defineProps({
  avatar: { type: String },
  application: { type: Object },
  rating: { type: Object },
});

const store = useStore();
const toast = useToast();
const route = useRoute();
const loading = ref(false);
const isEdit = ref(false);

const addEvaluation = async () => {
  try {
    loading.value = true;
    const data = route.params;
    const { message } = await store.dispatch('global/addApplicationEvaluation', {
      jobId: data.jobId,
      applicationId: data.applicationId,
      rating: props.rating,
    });

    toast.success(message, {
      timeout: 3000,
      hideProgressBar: true,
    });
  } catch (error) {
    console.log(error);
    toast.error(errorMessage(error) || 'Something went wrong (Please check internent connection)', {
      timeout: 5000,
      hideProgressBar: true,
    });
  } finally {
    loading.value = false;
  }
};

onMounted(() => {});
</script>
