<template>
  <div class="p-6 rounded-xl bg-white">
    <div class="flex justify-between items-center mb-6">
      <h2 class="text-[18px] leading-[150%]">Notes</h2>
    </div>

    <div class="border-b border-light-grey pb-2 mb-6">
      <div class="flex items-start mb-8">
        <img :src="avatar ? avatar : userImg" alt="image" class="w-[40px] h-[40px] rounded-full mr-4 mt-1" />
        <div>
          <div class="flex items-center space-x-2">
            <h2 class="font-manrope font-bold leading-[160%]">
              {{ application?.job?.postedBy?.name }}
            </h2>
            <p class="text-[#ADADAD] text-[10px]">added a comment</p>
          </div>
          <p class="text-light-gray-200 text-[14px] leading-[170%] font-manrope" v-html="prevNote"></p>
          <p class="text-[#ADADAD] text-[12px]">{{ moment(application?.updated_at).format('DD/MM/yyyy') }}</p>
        </div>
      </div>
    </div>

    <div>
      <p class="mb-3 text-[16px]">Add note</p>
      <div class="w-full rounded-xl border mb-4">
        <QuillEditor v-model:content="note" contentType="html" class="bg-white" theme="snow" toolbar="minimal" />
      </div>
      <div class="text-right">
        <button class="bg-brand-black text-white text-[16px] px-10 h-[56px] rounded-xl" @click="addNote">Save</button>
      </div>
    </div>
  </div>

  <loading-overlay v-if="loading" />
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { errorMessage } from '@/utils/helper';
import { useToast } from 'vue-toastification';
import LoadingOverlay from '../LoadingOverlay.vue';
import userImg from '../../assets/images/placeholder.png';
import moment from 'moment';

const props = defineProps({
  avatar: { type: String },
  application: { type: Object },
  prevNote: { type: String },
});

const emit = defineEmits(['confirm']);

const store = useStore();
const toast = useToast();
const loading = ref(false);
const note = ref('');

const addNote = async () => {
  try {
    loading.value = true;
    const { uuid, job } = props.application;
    const { message } = await store.dispatch('global/addApplicationEvaluation', {
      jobId: job.fid,
      applicationId: uuid,
      note: note.value,
    });

    toast.success(message, {
      timeout: 3000,
      hideProgressBar: true,
    });

    note.value = '';
    emit('confirm');
  } catch (error) {
    toast.error(errorMessage(error) || 'Something went wrong (Please check internent connection)', {
      timeout: 5000,
      hideProgressBar: true,
    });
  } finally {
    loading.value = false;
  }
};
</script>
