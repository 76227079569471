<template>
  <loader v-if="!pageLoaded || loading" />
  <div v-if="pageLoaded">
    <!-- <div v-if="allApplication.length < 1">
      <Empty button :title="'There are no applications made yet.'"
        :text="'Your next best candidate is waiting for you. We have thousands of applicants looking for a new journey. Make use of our all-in-one applicant tracking system'">
        <p>No applicants yet</p>
      </Empty>
    </div> -->
    <div>
      <div class="grid md:grid-cols-3 gap-4 mb-8">
        <job-summary-card label="Total Number of Applications" :count="statusCount.applications" application />
        <job-summary-card label="Open Jobs" :count="statusCount.open_jobs" application />
        <job-summary-card label="Closed Jobs" :count="statusCount.closed_jobs" application />
      </div>
      <!-- <div class="flex items-center justify-end">
        <TamButton class="px-4" type="submit" color="secondary" label="Export CSV" :icon="Download" />
      </div> -->

      <!-- <div class="flex items-center justify-end px-6">
        <span class="text-[#FD254A] text-[14px] font-semibold mr-2">{{ tab == 1 ? 'Kanban' : 'Table' }} View</span>
        <div class="inline-flex items-center bg-[#F8F8F8] rounded-3xl p-1">
          <div class="w-[30px] h-[30px] rounded-full flex items-center justify-center bg-[#FD254A] cursor-pointer" @click="tab = 2">
            <img src="@/assets/icons/list.svg" alt="" />
          </div>
          <div class="w-[30px] h-[30px] rounded-full flex items-center justify-center cursor-pointer" @click="tab = 1">
            <img src="@/assets/icons/grid.svg" alt="" />
          </div>
        </div>
      </div> -->

      <div class="flex justify-between items-center mb-7">
        <p class="text-lg text-[#687588] font-bold md:mt-3">Job Applications</p>
        <div class="">
          <div class="flex items-center space-x-6">
            <tam-button
              class="bg-white hover:bg-white border border-light-grey rounded-xl"
              label="Filter"
              :icon="FilterLine"
              @click="showFilter = true"
            />
            <tam-button class="text-light-gray bg-white hover:bg-white border border-light-grey rounded-xl" label="Jun 01- Jun 30" :icon="Calendar" />
          </div>
        </div>
      </div>

      <!-- <div v-if="tab == 1">
         <div v-if="allApplication.length < 1" class="flex justify-center flex-col items-center mt-40">
          <p class="font-FoundersMedium text-black text-2xl mb-4">No Application available</p>
          <button class="bg-black rounded-md text-white py-4 px-8" @click="openJob">Post a Job</button>
        </div> 
        <div
          class="bg-white p-6 rounded-2xl grid grid-rows-2 lg:flex items-center justify-between mb-4 cursor-pointer"
          v-for="item in 5"
          :key="item"
          @click="openApplication"
        >
          <div>
            <div class="flex items-center space-x-4 mb-2.5">
              <p class="text-lg font-bold text-[#1A1A1A]">Operation Research</p>
              <span class="px-4 py-1 bg-[#E5FFF6] rounded-lg"><p class="text-[#00AD73] font-normal text-[10px]">in progress</p></span>
            </div>
            <div class="text-[#8F8F8F] font-normal text-sm flex items-center gap-x-2.5">
              <p>Remote</p>
              <p class="mb-2 text-[#DEDEDE] text-base">.</p>
              <p>Nigeria</p>
              <p class="mb-2 text-[#DEDEDE] text-base">.</p>
              <p>Exp 0-1 years</p>
            </div>
          </div>
          <div class="grid lg:grid-cols-3 gap-x-8 place-itemsend">
            <div class="flex -space-x-4 rtl:space-x-reverse">
               <img class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="@/assets/images/piggyvest.png" alt="" /> 
              <img
                class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                src="@/assets/images/placeholder.png"
                alt=""
                v-for="i in 5"
                :key="i"
              />
            </div>
            <div class="flex items-center space-x-4">
              <span class="text-sm text-[#687588] font-normal space-y-2">
                <p>95</p>
                <p>Candidates</p>
              </span>
              <span class="text-sm text-[#687588] font-normal space-y-2">
                <p>0</p>
                <p>Shortlisted</p>
              </span>
            </div>
            <div class="place-selfstart">
              <span class="text-sm text-[#687588] font-normal space-y-2">
                <p>0</p>
                <p>Added 3m ago</p>
              </span>
            </div>
          </div>
        </div>
         <div class="calc[min-h-screen - 5vh] flex overflow-x-scroll pt-12 pb-6 custom-scroll">
          <kanban-board :stages="kanban.stages" :blocks="kanban.blocks" @update-block="updateBlock" :stateMachineConfig="stateMachineConfig">
            <template v-for="stage in kanban.stages" v-slot:[stage] :key="stage">
              <p class="text-[#12080F rounded rounded-b-none p-5 text-opacity-80 font-semibold tracking-wide text-base">
                {{ stage }}
                <span
                  :class="{
                    'text-brand-button': ['pending', 'reviewed'].includes(stage),
                    'text-brand-green': stage == 'hired',
                    'text-brand-danger': ['rejected', 'interviewed'].includes(stage),
                  }"
                  >{{ statusCount ? statusCount[stage] : kanban.blocks.filter((z) => z.status == stage).length }}</span
                >
              </p>
            </template>

            <template v-for="block in kanban.blocks" v-slot:[block.id] :key="block">
              <task-card :task="block" class="mt-3 cursor-move" @click="updateGeneralDialog(block)"></task-card>
            </template>
          </kanban-board>
        </div>

        <div class="flex justify-center py-6">
          <TamButton
            class="text-[14px] font-semibold rounded-md px-5"
            color="primaryLight"
            :label="`Load more`"
            :disabled="paginateContent?.meta?.current_page == paginateContent?.meta?.last_page"
            @click="loadMore"
          />
        </div> 
      </div> -->
      <div v-if="allApplication.length < 1" class="my-10 flex justify-center flex-col items-center mt-40 w-full mx-auto">
        <p class="font-FoundersMedium text-black text-2xl mb-4">No Applications available</p>
        <p class="text-sm text-[#979EA8] text-center">
          This handy tool helps you create dummy text for all your layout needs. <br />
          We are gradually adding new functionality and we welcome your suggestions and feedback.
        </p>
        <button class="bg-black rounded-md text-white py-4 px-8 mt-4" @click="openJob">Post a Job</button>
      </div>
      <div class="mt-8" v-else>
        <div class="relative overflow-x-auto sm:rounded-lg border">
          <table class="w-full text-sm text-left text-gray-500 p-4">
            <thead class="text-xs text-white font-bold bg-brand-black">
              <tr>
                <th scope="col" class="px-6 py-3 whitespace-nowrap text-left" v-for="item in tableDetails" :key="item">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in allApplication"
                :key="item.uuid"
                class="bg-white border-b hover:bg-gray-50 cursor-pointer"
                @click="openApplication(item.job?.uuid)"
              >
                <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  <div class="flex items-center cursor-pointer">
                    <!-- @click.prevent="updateGeneralDialog(item)" -->
                    <div>
                      {{ item?.job.role_title }} <br />
                      <small class="text-[10px] text-[#8F8F8F] capitalize mx-1">{{ item?.job.work_type }}</small>
                      <i class="text-sm text-[#8F8F8F] font-extrabold">.</i>
                      <small class="text-[10px] text-[#8F8F8F] capitalize mx-1">{{ item?.job.country?.name }}</small>
                      <i class="text-sm text-[#8F8F8F] font-extrabold">.</i>
                      <small class="text-[10px] text-[#8F8F8F] capitalize mx-1">{{ item?.job?.level_of_experience?.split('_').join(' ') }}</small>
                    </div>
                  </div>
                </td>
                <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap capitalize">
                  {{ item?.job.job_category_name }}
                </td>
                <!-- <td scope="row"> -->
                <!-- {{ item?.job_status }} -->
                <!-- <span>
                    <p class="text-[#00AD73] font-normal text-[10px]"></p>
                  </span> -->
                <td class="px-4 py-4 whitespace-nowrap capitalize">
                  <div
                    class="rounded-full py-1 px-2.5"
                    :class="item.job.job_status === 'active' ? 'bg-[#E5FFF6] text-[#00AD73]' : 'bg-[#FAFAFA] text-[#1A1A1A]'"
                  >
                    <p class="text-center font-normal text-[10px]">{{ item?.job.job_status }}</p>
                  </div>
                </td>
                <!-- </td> -->
                <td scope="row" class="px-6 py-4 text-right whitespace-nowrap">
                  <img :src="item?.job.postedBy?.avatar_url" alt="image" class="w-8 h-8 rounded-full mr-2" />
                </td>
                <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {{ item?.job.applications_count || 7 }}
                </td>
                <!-- <td class="px-6 py-4 text-gray-600 text-xs font-extralight inline-flex item?s-center">
                  <a :href="item?.resume_url" @click.prevent="resumeUrl(item?.resume_url)">CV.pdf</a>
                </td> -->
                <td class="px-6 py-4 font-light">
                  {{ moment(item?.job.created_at).format('D MMM, YYYY') }}
                </td>
                <!-- <td class="px-6 py-4">
                  <Menu as="div" class="relative inline-block text-left">
                    <div>
                      <MenuButton class="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-700">
                        {{ item.status }}
                        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                      </MenuButton>
                    </div>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="absolute z-10 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <div class="py-1">
                          <MenuItem v-for="(status, index) in kanban.stages" :key="index">
                            <a
                              href="#"
                              class="block px-4 py-2 hover:bg-brand-hover hover:rounded-md text-black"
                              @click.prevent="
                                updateGeneralDialog(item);
                                selectedItemStatus = status;
                              "
                            >
                              {{ status }}
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </transition>
                  </Menu>
                </td>
                <td class="px-6 py-4">
                  <Menu as="div" class="relative inline-block text-left">
                    <div>
                      <MenuButton class="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-700">
                        <More fill="black" />
                      </MenuButton>
                    </div>

                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="relative z-10 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <MenuItem>
                          <a
                            href="#"
                            @click.prevent="updateGeneralDialog(item)"
                            class="block px-4 py-2 hover:bg-brand-hover hover:rounded-md text-black"
                            >Manage Application</a
                          >
                        </MenuItem>
                      </MenuItems>
                    </transition>
                  </Menu>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <Paginate
      :from="paginateContent.meta.from"
      :to="paginateContent.meta.to"
      :total="paginateContent.meta.total"
      :current="paginateContent.meta.current_page"
      :next="paginateContent.links.next"
      :prev="paginateContent.links.prev"
      :pages="paginateContent.meta.links"
      @move="doPaginate"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
// import Datepicker from 'vue3-datepicker';
import moment from 'moment';
import { useRouter, useRoute } from 'vue-router';
import Draggable from 'vuedraggable';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Close, Upload, Trash, FilterLine, Calendar } from '@/icons.js';
import CheckMark from '@/assets/icons/checkmark.svg?inline';
import TaskCard from '@/components/TaskCard.vue';
import Empty from '@/components/Empty.vue';
import TamButton from '@/components/TamButton.vue';
import JobSummaryCard from '@/components/job/JobSummaryCard.vue';
import TamModal from '@/components/TamModal.vue';
import Loader from '@/components/LoadingOverlay.vue';
import Paginate from '@/components/Paginate.vue';
import { role } from '@/utils/helper';

import { errorMessage, saveResumeUrl, alert } from '@/utils/helper';
import More from '@/assets/icons/more2.svg?inline';

const props = defineProps({
  type: { type: String, default: 'all' },
  job: { type: Object },
});

const tableDetails = ref(['Job Title', 'Category', 'Status', 'Recruiters', 'Applications', 'Created Date']);

const store = useStore();
const router = useRouter();
const route = useRoute();

const pageLoaded = ref(false);
const draggedItem = ref();
const selectedItem = ref();
const selectedItemStatus = ref('');
const tab = ref(1);

const showReviewDialog = ref(false);
const showRejectDialog = ref(false);
const showInterviewDialog = ref(false);
const showHireDialog = ref(false);
const showGeneralDialog = ref(false);

const allApplication = ref([]);
const applications = ref([]);
// const allJobs = ref([]);
const enums = ref([]);
const statusCount = ref({});
const loading = ref(false);
const jobParams = ref({});

const updateGeneralDialog = (stage) => {
  selectedItem.value = stage;
  selectedItemStatus.value = selectedItem.value.status;
  showGeneralDialog.value = true;
};

// Add new job, if application is empty
const openJob = () => {
  router.push('/jobs');
};

const openApplication = (id) => {
  router.push(`/tracking/singleApplication/${id}`);
};

// Upload file proccess begins
const files = ref([]);
const selectedFile = ref(null);
const file = ref([]);
const error = ref(false);

const doPaginate = async ({ params }) => {
  await store.dispatch('global/getAllApplication', { params });
};

const paginateContent = computed(() => {
  return store.getters['global/getApplicationsPayload'];
});

const updateCandidateStatus = () => {
  const status = selectedItemStatus.value;
  const id = selectedItem.value.uuid;
  switch (status) {
    case 'interviewed':
      interviewDetails.value.message = message;
      interview(id);
      break;
    case 'hired':
      hiredDetails.value.message = message;
      hire(id);
      break;
    case 'rejected':
      rejectDetails.value.message = message;
      reject(id);
      break;
    case 'reviewed':
      reviewed(id);
  }
};

const getAllApplication = async (params) => {
  try {
    loading.value = true;
    allApplication.value =
      props.type === 'job'
        ? await store.dispatch('global/getSingleJobApplications', props.job?.uuid)
        : await store.dispatch('global/getAllApplication', {
            forceReload: true,
            params,
          });

    allApplication.value = props.type === 'job' ? allApplication.value.map((item) => ({ ...item, job: props.job })) : allApplication.value;
    applications.value.push(...allApplication.value);

    // kanban.value.blocks = applications.value.map((x) => {
    //   return {
    //     ...x,
    //     id: x.uuid,
    //   };
    // });
  } catch (error) {
  } finally {
    loading.value = false;
  }
};

const getJobs = computed(() => {
  return store.getters['global/getAllJobs'];
});
// talent cv
const resumeUrl = (url) => {
  saveResumeUrl(store, url);
};

onMounted(async () => {
  try {
    enums.value = await store.dispatch('global/getEnums');
    await getAllApplication();
    statusCount.value = await store.dispatch('global/getApplicationOverviewCount');
  } catch (error) {
  } finally {
    pageLoaded.value = true;
  }
});
</script>

<style lang="scss" scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}

/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  @apply border opacity-50 border-blue-500 bg-gray-200
  /* opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1; */;
}

.drag-container::v-deep {
  .drag-inner-list {
    //padding-bottom: 10rem !important;
    height: 480px !important;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.6vw;
      background: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      background: #ff2449;
      background: #ff2449;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: -webkit-linear-gradient(transparent, #00c6ff);
      background: linear-gradient(transparent, #00c6ff);
    }
  }
}
</style>
