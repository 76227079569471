<template>
  <Dialog v-show="isActive" class="backdrop-blur-sm" extend-class="md:max-w-2xl my-16 mt-[12rem]">
    <div class="my-6 bg-white rounded- w-full">
      <div class="flex justify-between px-6">
        <div class="w-full">
          <div class="flex justify-between items-start">
            <div class="mb-6">
              <h1 class="text-[24px] font-semibold leading-[130%] text-brand-black mb-2">Candidate Overview</h1>
              <div class="flex space-x-3 items-center">
                <img src="@/assets/icons/infoline.svg" alt="" />
                <small class="text-light-gray">RE: {{ application?.job?.role_title }}</small>
              </div>
            </div>

            <img src="@/assets/icons/closeline.svg" alt="" @click="close" />
          </div>

          <div>
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img :src="application?.candidate?.avatar" class="w-8 h-8 mr-4 object-cover rounded" alt="" />
                <div>
                  <p class="text-brand-black font-medium text-base">
                    {{ application?.candidate?.name }}
                  </p>
                </div>
              </div>
              <div class="flex items-center">
                <a
                  :href="getLink(application?.candidate?.social_links)"
                  :disabled="!getLink(application?.candidate?.social_links)"
                  target="_blank"
                  class="mr-2 object-cover rounded cursor-pointer"
                  ><img src="../../assets/images/linkedin.png" class="w-[27px] h-[27px]" alt="linkedin"
                /></a>
                <img
                  src="@/assets/icons/pdf.svg"
                  class="w-[30px] h-[30px] cursor-pointer"
                  alt="resume"
                  @click.prevent="resumeUrl(application?.candidate?.resume)"
                />
              </div>
            </div>
          </div>

          <div>
            <div class="mt-10">
              <div class="md:flex items-center justify-end md:flex-row md:gap-[6px] gap-4 grid mb-6">
                <div>
                  <p class="text-sm font-semibold text-[#12080F]/70 mb-2">CHANGE STATUS</p>
                  <select
                    id="status"
                    v-model="applicationStatus"
                    class="text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
                  >
                    <option disabled value="" selected>Select status</option>
                    <option v-for="(item, index) in kanban.stages" :key="index" :value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>

              <template v-if="applicationStatus == 'interviewed'">
                <p class="text-sm font-semibold text-[#12080F]/70 mb-2">SCHEDULE INTERVIEW</p>
                <div class="md:flex items-center justify-between md:flex-row md:gap-[6px] gap-4 grid">
                  <div class="border rounded-lg border-[#12080F26]/10 h-10 grow">
                    <Datepicker v-model="interviewDetails.date" class="" />
                  </div>
                  <div class="border rounded-lg border-[#12080F26]/10 h-10 grow">
                    <vue-timepicker :modelValue="interviewDetails.time" @update:modelValue="updateInterviewTime" class=""></vue-timepicker>
                  </div>
                  <select
                    id="timezone"
                    v-model="interviewDetails.timezone"
                    class="rounded-lg border-[#12080F26]/10 appearance-none focus:outline-none"
                  >
                    <option disabled value="" selected>Select timezone</option>
                    <option v-for="item in enums.timezones" :key="item" :value="item.key">
                      {{ item.value }}
                    </option>
                  </select>
                </div>
              </template>
            </div>

            <div class="mt-8 mb-6" v-if="canSendMsg">
              <div class="flex justify-between items-center mb-2">
                <p class="inline-block text-[14px] text-brand-black mb-2 font-manrope font-bold">SEND EMAIL</p>
                <p class="font-manrope font-bold">
                  FROM: <span class="inline-block text-[14px] text-brand-black mb-2 font-manrope font-bold">{{ userDetails.email }}</span>
                </p>
              </div>
              <div class="">
                <textarea
                  v-model="message"
                  class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
                  rows="7"
                ></textarea>
                <p class="text-brand-primary text-[14px] font-manrope mt-2">A copy will be sent to your email</p>
              </div>
            </div>

            <div class="mt-11 mb-6" v-else>
              <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Cover letter</label>
              <div class="rounded-lg border-[#12080F26]/10 appearance-none focus:outline-none bg-light-gray-100">
                <p class="p-4">{{ application?.note }}</p>
              </div>
            </div>
          </div>

          <div class="mb-6" v-if="applicationStatus == 'hired'">
            <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Upload Offer letter</label>
            <div
              class="border border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 w-full p-3 px-6 sm:px-4 flex justify-between items-center"
            >
              <label for="dropzone-file" class="flex items-center cursor-pointer">
                <img src="@/assets/icons/upload2.svg" alt="" class="mr-4" />
                <div>
                  <p>Tap to upload</p>
                  <small>CSV | 10MB max.</small>
                </div>
                <input ref="selectedFile" id="dropzone-file" name="file" type="file" class="hidden" accept=".pdf,.doc,.docx" @change="previewOffer" />
              </label>
              <label for="dropzone-file" class="bg-brand-black px-6 sm:px-4 py-3 text-white rounded-xl block cursor-pointer"> Upload </label>
            </div>
          </div>

          <!-- <div class="pt-4 mb-6 border border-[#F0F0F0] rounded-lg">
            <div class="w-full border-b border-[#F0F0F0] px-6 pb-3">
              <div class="flex items-end space-x-3">
                <span>To</span>
                <input
                  type="text"
                  class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0 placeholder:bg-light-gray-100 text-sm rounded-xl"
                  placeholder="Type an email address..."
                />
              </div>
            </div>
            <div class="w-full border-b border-[#F0F0F0] px-6 py-3 pb-4">
              <div class="flex items-end space-x-3">
                <span>Subject</span>
                <input type="text" class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0" placeholder="Candidate Profile" />
              </div>
            </div>
            <div class="w-full border-b border-[#F0F0F0]">
              <QuillEditor v-model:content="shareMessage.message" contentType="html" class="bg-white" theme="snow" toolbar="minimal" />
            </div>
          </div> -->

          <div class="pt-2 flex space-x-6 items-center">
            <button type="button" @click.prevent="close" class="bg-white p-4 py-3 border border-brand-black rounded-lg w-1/2">Cancel</button>
            <button @click="updateCandidateStatus" class="bg-brand-black text-white p-4 py-3 rounded-lg w-1/2">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>

  <loading-overlay v-if="loading" />
</template>

<script setup>
import Dialog from '@/components/Dialog.vue';
import { ref, computed, onMounted, onBeforeUpdate } from 'vue';
import { useStore } from 'vuex';
import { errorMessage, alert, saveResumeUrl } from '@/utils/helper';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import moment from 'moment';

const props = defineProps({
  isActive: { type: Boolean, default: false },
  status: { type: String },
  application: { type: Object },
});

const store = useStore();
const enums = ref([]);
const loading = ref(false);
const message = ref('Dear {Candidate}, Congratulations. We are pleased to give you an offer....');
const applicationStatus = ref('');
const interviewDetails = ref({
  message:
    'Hello {name} Thanks for submitting your availability for the {role}. You are confirmed for your interview on: \n \n Date/Time: {date, time} \n Interviewers: {name}',
  date: new Date(),
  time: '12:00',
  timezone: '',
});

const hiredDetails = ref({
  message: 'Dear {Candidate}, Congratulations. We are pleased to give you an offer....',
});

const rejectDetails = ref({
  message:
    'Hello {Candidate},Thanks for submitting your availability for the {Role}. Unfortunately, we cannot proceed with your application at this time.',
});

const kanban = ref({
  stages: ['pending', 'reviewed', 'interviewed', 'hired', 'rejected'],
});

// Upload file proccess begins
const files = ref([]);
const selectedFile = ref(null);
const file = ref([]);
const error = ref(false);

const emit = defineEmits(['confirm', 'close']);

const confirm = () => {
  emit('confirm');
};

const close = () => {
  emit('close');
};

const previewOffer = (e) => {
  files.value = Array.from(e.target.files) || [];
  if (files.value.map((x) => x.size) > 10000000) {
    error.value = true;
  }
  file.value = e.target.files[0];
  console.log(file.value);
};

const clearFile = () => {
  files.value = [];
  error.value = false;
  disabled.value = true;
};

const resumeUrl = (url) => {
  saveResumeUrl(store, url);
};

// Set time on change
const updateInterviewTime = (eventData) => {
  interviewDetails.value.time = eventData;
};

const getLink = (links) => {
  if (!Array.isArray(links)) return '';
  if (links.length === 0) return '';
  return links[0]?.url;
};

// Endpoint to update application to reviewed
const reviewed = async (id) => {
  try {
    loading.value = true;
    await store.dispatch('global/updateApplication', { id: id, status: 'reviewed' });
    alert('Application moved to review stage', 'success')
    emit('confirm');
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
};

// Endpoint to reject application
const reject = async (id) => {
  try {
    const data = {
      id,
      message: rejectDetails.value.message,
    };

    loading.value = true;
    await store.dispatch('global/rejectApplicant', data);
    alert('Application rejected', 'success')
    emit('confirm');
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
};

// Endpoint to schedule interview
const interview = async (id) => {
  try {
    const data = {
      id,
      message: interviewDetails.value.message,
      date: moment(interviewDetails.value.date).format('DD/MM/yyyy'),
      time: interviewDetails.value.time,
      timezone: interviewDetails.value.timezone,
    };
    loading.value = true;

    await store.dispatch('global/scheduleInterview', data);
    alert('Application moved to interview stage', 'success')
    emit('confirm');
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
};

// Endpoint to hire applicant
const hire = async (id) => {
  try {
    const data = new FormData();
    if (file.value) {
      data.append('offer_letter', file.value);
    }
    data.append('message', hiredDetails.value.message);
    loading.value = true;

    await store.dispatch('global/hireApplicant', { id, data });
    alert('Application moved to hire stage', 'success')
    emit('confirm');
  } catch (error) {
    const data = new FormData();
    if (file.value) {
      data.append('offer_letter', file.value);
    }

    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
};

const updateCandidateStatus = () => {
  const status = applicationStatus.value;
  const id = props.application.uuid;
  switch (status) {
    case 'interviewed':
      interviewDetails.value.message = message;
      interview(id);
      break;
    case 'hired':
      hiredDetails.value.message = message;
      hire(id);
      break;
    case 'rejected':
      rejectDetails.value.message = message;
      reject(id);
      break;
    case 'reviewed':
      reviewed(id);
  }
};

const canSendMsg = computed(() => {
  const status = applicationStatus.value;
  return status != 'pending' && status != 'reviewed';
});

const userDetails = computed(() => {
  return store.state.auth.userDetails;
});

onMounted(async () => {
  enums.value = await store.dispatch('global/getEnums');
});

onBeforeUpdate(() => {
  applicationStatus.value = '';
  if (props.status != '') applicationStatus.value = props.status;
});
</script>

<style lang="scss" scoped>
.ql-toolbar.ql-snow {
  border: none !important;
  border-bottom: 1px solid #fafafa;
}

.ql-editor.ql-blank,
.ql-editor.ql-blank {
  border: none !important;
  border-bottom: 1px solid #fafafa !important;
}
</style>
