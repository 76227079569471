<template>
  <div class="p-6 rounded-xl bg-white">
    <div class="flex justify-between items-center mb-6">
      <h2 class="text-[18px] leading-[150%]">Links and Social Media</h2>
    </div>

    <div class="border border-light-grey p-3 rounded-xl flex items-center justify-between space-x-4">
      <input type="text" placeholder="Enter URL available" class="border-0 outline-0 focus:ring-0 text-[14px]" />
      <select name="" id="" class="border border-light-grey outline-0 focus:outline-0 focus:ring-0 bg-light-gray-100 rounded-md text-[14px]">
        <option>Background Check</option>
        <option>Video Screening</option>
        <option>Portfolio</option>
        <option>Skill Set</option>
      </select>
    </div>

    <div class="border border-light-grey p-3 rounded-xl flex items-center justify-between space-x-4 mt-4" v-for="item in links.slice(1)" :key="item">
      <input type="text" placeholder="Enter URL available" class="border-0 outline-0 focus:ring-0 text-[14px]" />
      <select name="" id="" class="border border-light-grey outline-0 focus:outline-0 focus:ring-0 bg-light-gray-100 rounded-md text-[14px]">
        <option>Background Check</option>
        <option>Video Screening</option>
        <option>Portfolio</option>
        <option>Skill Set</option>
      </select>
    </div>

    <button class="inline-flex items-center space-x-3 mt-3" @click="addToLinks">
      <img src="../../assets/icons/plus2.svg" alt="" />
      <span class="text-brand-primary text-[15px]">Add more links</span>
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const links = ref(['']);

const addToLinks = () => {
  links.value.push('');
};
</script>
