<template>
  <loader v-if="!pageLoaded || loading" />
  <div v-if="pageLoaded">
    <div>
      <back />
      <div class="flex items-center justify-between mb-7">
        <div>
          <p class="text-lg text-brand-black font-bold md:mt-3 capitalize">{{ jobDetails?.role_title }}</p>
          <p class="text-sm font-normal text-[#8F8F8F] capitalize">
            {{ formatString(jobDetails?.work_type) }}, {{ jobDetails?.country?.name }} <span class="text-brand-black px-2"> > </span>
            <span class="text-brand-black font-normal text-sm capitalize">{{ jobDetails?.job_category_name }}</span>
          </p>
        </div>
        <div class="bg-[#FFE1ED] rounded-[10px] p-4 flex items-center cursor-pointer">
          <Stop />
          <p class="text-[#FE0D73] text-base">Stop Applications</p>
        </div>
      </div>

      <div>
        <Tab @changeTab="changeTab" :numberOfTabs="tabs" :currentTab="tabNumber" borderColor="border-brand-black" spread>
          <div v-if="tabNumber == 1">
            <div class="flex space-x-6">
              <div class="p-6 rounded-2xl bg-white mb-6 w-8/12 border border-light-grey">
                <p class="font-bold text-brand-black text-[14px]">Job description</p>
                <div v-html="jobDetails?.description" class="mt-2 description"></div>
              </div>

              <div class="w-4/12">
                <div class="p-6 rounded-2xl bg-white border border-light-grey mb-5">
                  <div class="mb-6" v-if="jobDetails?.salary_min">
                    <div class="flex items-center">
                      <div class="flex items-center justify-center mr-4 bg-[#FAFAFA] w-10 h-10 rounded-full">
                        <img src="../../assets/icons/moneys.svg" class="w-5 h-5" />
                      </div>
                      <div>
                        <small class="text-[14px] text-brand-black/50">Renumeration</small>
                        <h3 class="text-[18px] text-brand-black font-semibold">
                          {{ jobDetails?.salary_currency }}
                          {{ formatNumber(jobDetails?.salary_min) }} - {{ formatNumber(jobDetails?.salary_max) }}
                          <span class="font-semibold">{{ period[jobDetails?.pay_period] }}</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="mb-6">
                    <div class="flex items-center">
                      <div class="flex items-center justify-center mr-4 bg-[#FAFAFA] w-10 h-10 rounded-full">
                        <img src="../../assets/icons/category1.svg" class="w-5 h-5" />
                      </div>
                      <div>
                        <small class="text-[14px] text-brand-black/50">Category</small>
                        <h3 class="text-[18px] text-brand-black font-semibold capitalize">{{ jobDetails?.job_title_name }}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="mb-6">
                    <div class="flex items-center">
                      <div class="flex items-center justify-center mr-4 bg-[#FAFAFA] w-10 h-10 rounded-full">
                        <img src="../../assets/icons/briefcase.svg" class="w-5 h-5" />
                      </div>
                      <div>
                        <small class="text-[14px] text-brand-black/50">Employment Type</small>
                        <h3 class="text-[18px] text-brand-black font-semibold capitalize">{{ formatString(jobDetails?.type) }}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="mb-6">
                    <div class="flex items-center">
                      <div class="flex items-center justify-center mr-4 bg-[#FAFAFA] w-10 h-10 rounded-full">
                        <img src="../../assets/icons/star1.svg" class="w-5 h-5" />
                      </div>
                      <div>
                        <small class="text-[14px] text-brand-black/50">Minimum Experience Level</small>
                        <h3 class="text-[18px] text-brand-black font-semibold capitalize">{{ formatString(jobDetails?.level_of_experience) }}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="flex items-center">
                      <div class="flex items-center justify-center mr-4 bg-[#FAFAFA] w-10 h-10 rounded-full">
                        <img src="../../assets/icons/location1.svg" class="w-5 h-5" />
                      </div>
                      <div>
                        <small class="text-[14px] text-brand-black/50">Location</small>
                        <h3 class="text-[18px] text-brand-black font-semibold capitalize">
                          {{ jobDetails?.country?.name }} ({{ jobDetails?.work_type }})
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-6 rounded-2xl bg-white border border-light-grey mb-5">
                  <div class="mb-4" v-if="jobDetails?.must_have">
                    <h3 class="text-[18px] text-brand-black font-bold mb-6">Must-haves:</h3>
                    <div>
                      <ul class="list-disc pl-10">
                        <li class="mb-2 text-brand-black/50 text-[14px]" v-for="(item, index) in jobDetails?.must_have" :key="index">{{ item }}</li>
                      </ul>
                    </div>
                  </div>

                  <div class="" v-if="jobDetails?.skills">
                    <h3 class="text-[18px] text-brand-black font-bold mb-6">Skills:</h3>
                    <div>
                      <ul class="list-disc pl-10">
                        <li class="mb-2 text-brand-black/50 text-[14px] capitalize" v-for="(item, index) in jobDetails?.skills" :key="index">
                          {{ item }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="bg-white p-6 rounded-2xl grid grid-rows-2 lg:flex items-center justify-between mb-4" v-for="i in 5" :key="i">
              <div>
                <div class="flex items-center space-x-4 mb-2.5">
                  <p class="text-lg font-bold text-[#1A1A1A]">Operation Research</p>
                  <span class="px-4 py-1 bg-[#E5FFF6] rounded-lg"><p class="text-[#00AD73] font-normal text-[10px]">in progress</p></span>
                </div>
                <div class="text-[#8F8F8F] font-normal text-sm flex items-center gap-x-2.5">
                  <p>Remote</p>
                  <p class="mb-2 text-[#DEDEDE] text-base">.</p>
                  <p>Nigeria</p>
                  <p class="mb-2 text-[#DEDEDE] text-base">.</p>
                  <p>Exp 0-1 years</p>
                </div>
              </div>
              <div class="grid lg:grid-cols-3 gap-x-8 place-itemsend">
                <div class="flex -space-x-4 rtl:space-x-reverse">
                   <img class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="@/assets/images/piggyvest.png" alt="" /> 
                  <img
                    class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                    src="@/assets/images/placeholder.png"
                    alt=""
                    v-for="i in 5"
                    :key="i"
                  />
                </div>
                <div class="flex items-center space-x-4">
                  <span class="text-sm text-[#687588] font-normal space-y-2">
                    <p>95</p>
                    <p>Candidates</p>
                  </span>
                  <span class="text-sm text-[#687588] font-normal space-y-2">
                    <p>0</p>
                    <p>Shortlisted</p>
                  </span>
                </div>
                <div class="place-selfstart">
                  <span class="text-sm text-[#687588] font-normal space-y-2">
                    <p>0</p>
                    <p>Added 3m ago</p>
                  </span>
                </div>
              </div>
            </div> -->
          </div>

          <div v-if="tabNumber == 2" class="mt-8">
            <div class="mb-7">
              <!-- Filter and add job -->
              <div class="flex items-center gap-1">
                <div class="relative w-full">
                  <input
                    @keyup.enter="updateFilterParams"
                    v-model="filterKeys.search"
                    type="search"
                    id="default-search"
                    class="block w-full px-4 py-3 pr-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50"
                    placeholder="Search what you need"
                    required
                  />

                  <button class="absolute right-4 top-3 z-10">
                    <!-- <img src="../../assets/icons/search.svg" alt="" /> -->
                  </button>
                </div>
                <!-- <div class="flex items-center justify-end px-6">
                  <p class="text-brand-black min-w-[95px] mr-2">{{ tab == 1 ? 'Normal' : 'Table' }} View</p>
                  <div class="inline-flex items-center bg-[#F8F8F8] rounded-3xl p-1">
                    <div class="w-[30px] h-[30px] rounded-full flex items-center justify-center bg-brand-black cursor-pointer" @click="tab = 1">
                      <img src="../../assets/icons/list.svg" alt="" />
                    </div>
                    <div class="w-[30px] h-[30px] rounded-full flex items-center justify-center cursor-pointer" @click="tab = 2">
                      <img src="../../assets/icons/grid.svg" alt="" />
                    </div>
                  </div>
                </div> -->

                <tam-button
                  class="bg-white hover:bg-white border border-light-grey rounded-xl mr-3"
                  label="Filter"
                  :icon="FilterLine"
                  @click="showFilter = true"
                />
                <tam-button
                  @click="showInviteCandidateDialog = true"
                  class="rounded-xl mr-3 bg-black text-white hover:bg-black"
                  label="Invite Candidate"
                  :icon="Cross"
                />
              </div>
            </div>

            <div class="overflow-x-auto h-screen" v-if="pageLoaded">
              <table class="w-full text-sm text-left text-gray-500 p-4">
                <thead class="uppercase bg-brand-black text-white">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-4 text-[13px] whitespace-nowrap font-bold capitalize text-left"
                      v-for="item in tableDetails"
                      :key="item"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in applications" :key="item.uuid" class="bg-white border-b border-light-grey hover:bg-gray-50 cursor-pointer">
                    <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      <div class="flex items-center">
                        <img :src="item?.candidate?.avatar" alt="image" class="w-[32px] h-[32px] rounded-full mr-3" />
                        <div>
                          <p>{{ item?.candidate?.name }}</p>
                          <small>{{ item?.candidate?.email }}</small>
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-4">
                      <p class="capitalize">{{ item?.candidate?.role_title }}</p>
                    </td>

                    <td class="px-6 py-4 text-gray-600 text-xs font-extralight inline-flex items-center">
                      <div class="flex items-center">
                        <img src="../../assets/icons/pdf.svg" alt="" @click.prevent="resumeUrl(item?.resume_url)" />
                        <tam-button class="text[#8F00E6] text-[15px] text-brand-black" label="CV.pdf" />
                      </div>
                    </td>

                    <td class="px-2 py-4">
                      <span
                        :class="[
                          'px-6 py-2 text-[11px] rounded-xl',
                          item.status == 'open' ? 'text-[#00AD73] bg-[#E5FFF6]' : 'text-[#CB0156] bg-[#FFF0F6]',
                        ]"
                        >{{ item.status }}</span
                      >
                    </td>

                    <td class="px-6 py-4 font-light">
                      {{ moment(item?.created_at).format('D MMM, YYYY') }}
                    </td>
                    <td class="px-6 py-4 text-right">
                      <Menu as="div" class="relative inline-block text-left">
                        <div>
                          <MenuButton class="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-700">
                            <More />
                          </MenuButton>
                        </div>

                        <transition
                          enter-active-class="transition ease-out duration-100"
                          enter-from-class="transform opacity-0 scale-95"
                          enter-to-class="transform opacity-100 scale-100"
                          leave-active-class="transition ease-in duration-75"
                          leave-from-class="transform opacity-100 scale-100"
                          leave-to-class="transform opacity-0 scale-95"
                        >
                          <MenuItems
                            class="absolute z-10 origin-top right-0 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[190px]"
                          >
                            <div class="p-1 py-2">
                              <MenuItem>
                                <router-link
                                  :to="`/dashboard/companies/${item?.name}/${item?.uuid}/members`"
                                  class="inline-flex items-center px-4 py-2 text-brand-black"
                                >
                                  <img src="../../assets/icons/usersline.svg" class="mr-2" alt="" />
                                  <span> Share Resume</span>
                                </router-link>
                              </MenuItem>
                              <MenuItem>
                                <a href="#" @click.prevent="shareProfile(item)" class="inline-flex items-center px-4 py-2 text-brand-black">
                                  <img src="../../assets/icons/usersline.svg" class="mr-2" alt="" />
                                  <span> Share Profile</span>
                                </a>
                              </MenuItem>
                              <MenuItem>
                                <a
                                  href="#"
                                  @click.prevent="viewTalentApplication(item.uuid)"
                                  class="inline-flex items-center px-4 py-2 text-brand-black"
                                >
                                  <img src="../../assets/icons/eyeline.svg" class="mr-2" alt="" />
                                  <span>View Application</span>
                                </a>
                              </MenuItem>
                              <MenuItem>
                                <a href="#" @click.prevent="emailTalent(item)" class="inline-flex px-4 py-2 text-brand-black">
                                  <span>Email Talent</span>
                                </a>
                              </MenuItem>
                            </div>
                          </MenuItems>
                        </transition>
                      </Menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <paginate
              :from="paginateContent?.meta?.from"
              :to="paginateContent?.meta?.to"
              :total="paginateContent?.meta?.total"
              :currentPage="paginateContent?.meta?.current_page"
              :nextPage="paginateContent?.links?.next"
              :prevPage="paginateContent?.links?.prev"
              :links="paginateContent?.meta?.links"
              :navigations="paginateContent?.links"
              :perPage="paginateContent?.meta?.per_page"
              @move="doPaginate"
            /> -->
          </div>
        </Tab>
      </div>
    </div>

    <send-talents-mail-dialog
      :open="showSendTalentMail"
      @on-close="showSendTalentMail = false"
      :talentDetails="talentDetails?.candidate"
      from="track"
    />
    <share-profile-dialog :open="showProfileShareDialog" @on-close="showProfileShareDialog = false" :talentDetails="talentDetails" />
    <invite-to-job v-if="showInviteCandidateDialog" @on-close="showInviteCandidateDialog = false" :jobDetails="jobDetails" from="apply" />
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { useRouter, useRoute } from 'vue-router';
import Draggable from 'vuedraggable';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Close, Upload, Trash, DownloadCV, FilterLine, Cross, Stop } from '@/icons.js';
import CheckMark from '@/assets/icons/checkmark.svg?inline';
import TaskCard from '@/components/TaskCard.vue';
import Empty from '@/components/Empty.vue';
import TamButton from '@/components/TamButton.vue';
import TamModal from '@/components/TamModal.vue';
import Loader from '@/components/LoadingOverlay.vue';
import Paginate from '@/components/Paginate.vue';
import { role } from '@/utils/helper';
import { errorMessage, saveResumeUrl, alert, formatNumber, formatString } from '@/utils/helper';
import More from '@/assets/icons/more2.svg?inline';
import InviteToJob from './InviteToJob.vue';
import ShareProfileDialog from './ShareProfile.vue';
import SendTalentsMailDialog from '@/components/talent/SendTalentsMail.vue';
import Tab from '../Tab.vue';
import Back from '@/components/BackButton.vue';
import InviteCandidateDialog from '../job/InviteCandidateDialog.vue';

const props = defineProps({
  type: { type: String, default: 'all' },
  job: { type: Object },
});

const tableDetails = ref(['Name', 'Role', 'CV/Resume', 'Application Status', 'Date Applied', 'Action']);

const store = useStore();
const router = useRouter();
const route = useRoute();

const pageLoaded = ref(false);
const tabNumber = ref(1);
const jobDetails = ref();
const tabs = ref([
  {
    name: 'Job Detials',
  },
  {
    name: 'Applications',
  },
]);
const period = ref({
  per_month: 'monthly',
  per_year: 'yearly',
  per_day: 'daily',
  per_week: 'weekly',
});
const filterKeys = ref({
  search: '',
});
// const tab = ref(1);
// const checkbox = ref(false);
const loading = ref(false);
const showFilter = ref(false);
const applications = ref([]);
const showInviteCandidateDialog = ref(false);
const showProfileShareDialog = ref(false);
const showSendTalentMail = ref(false);
const talentDetails = ref(null);

const changeTab = (value) => {
  tabNumber.value = value;
};
const paginateContent = computed(() => {
  return store.getters['global/getJobApplicationsPayload'];
});

const doPaginate = async ({ params }) => {
  // getAllApplications(params);
  const res = await store.dispatch('global/getSingleJobApplications', { params }, route.params.id);
  console.log(res);
};

const updateFilterParams = () => {
  const params = Object.entries(filterKeys.value).reduce((a, [k, v]) => (v == '' ? a : ((a[k] = v), a)), {});
  if (!Object.keys(params).length) return false;
  getAllApplications(params);
};

const getAllApplications = async (uuid) => {
  try {
    loading.value = true;
    // const uuid = route.params.id;
    applications.value = await store.dispatch('global/getSingleJobApplications', uuid);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const openJob = () => {
  router.push('/new-job');
};

const viewTalentApplication = (uuid) => {
  // router.push(`/tracking/previewApplication/${route.params.id}/application/${uuid}`);
  const routeData = router.resolve({ name: 'EmployerTrackingPreview', params: { jobId: route.params.id, applicationId: uuid } });
  window.open(routeData.href, '_blank');
};

const shareProfile = (talent) => {
  talentDetails.value = talent;
  showProfileShareDialog.value = true;
};

const emailTalent = (talent) => {
  talentDetails.value = talent;
  showSendTalentMail.value = true;
};

const updateApplicationStatus = async (status) => {
  loading.value = true;
  try {
    const res = await store.dispatch('global/updateApplicationStatus', { id: route.params.id, status });
    toast.success(res.message, {
      timeout: 3000,
      hideProgressBar: true,
    });
    await getJobDetails();
    if (error.response && error.response.status === 422) {
    }
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
  } finally {
    loading.value = false;
  }
};

const getJobDetails = async () => {
  try {
    const uuid = route.params.id;
    jobDetails.value = await store.dispatch('global/getSingleJob', uuid);

    await getAllApplications(uuid);
  } catch (error) {
  } finally {
    pageLoaded.value = true;
  }
};

onMounted(async () => {
  await getJobDetails();
});
</script>

<style lang="scss" scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}

/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  @apply border opacity-50 border-blue-500 bg-gray-200
  /* opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1; */;
}

.drag-container::v-deep {
  .drag-inner-list {
    //padding-bottom: 10rem !important;
    height: 480px !important;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.6vw;
      background: #ccc;
    }

    &::-webkit-scrollbar-thumb {
      background: #ff2449;
      background: #ff2449;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: -webkit-linear-gradient(transparent, #00c6ff);
      background: linear-gradient(transparent, #00c6ff);
    }
  }
}
</style>
