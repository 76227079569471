<template>
  <main>
    <div class="fixed inset-0 z-40">
      <!-- OVERLAY -->
      <div class="fixed inset-0 bg-gray-900 opacity-70 backdrop-blur-3xl"></div>
      <!-- FILTER -->
      <div class="fixed top-0 bottom-0 right-0 max-w-2xl w-9/12 md:w-1/2 bg-white p-9">
        <p class="text-brand-black text-2xl font-FoundersSemiBold">Invite a Candidate</p>

        <div class="bg-[#FEFAF1] rounded-xl border border-[#DEDEDE] p-4 mb-6">
          <div>
            <p class="text-sm font-normal mb-2.5">{{ jobDetails.postedBy.name }}</p>
          </div>
          <p class="text-lg font-bold mb-2.5">{{ jobDetails.role_title }}</p>
          <div class="text-[#8F8F8F] font-normal text-sm flex items-center gap-x-2.5">
            <p>{{ jobDetails.work_type }}</p>
            <p class="mb-2 text-[#DEDEDE] text-base">.</p>
            <p>{{ jobDetails.country.name }}</p>
            <p class="mb-2 text-[#DEDEDE] text-base">.</p>
            <p>Exp {{ jobDetails.level_of_experience }}</p>
          </div>
        </div>
        <div class="overflow-y-auto h-[85vh]">
          <div class="pt-4 mb-6 border border-[#F0F0F0] rounded-lg">
            <div class="w-full border-b border-[#F0F0F0] px-6 pb-3">
              <div class="flex items-end space-x-3">
                <span>To</span>
                <input
                  type="email"
                  class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0 placeholder:bg-light-gray-100 text-sm grow"
                  placeholder="Type an email address..."
                  v-model="details.email"
                />
              </div>
            </div>
            <div class="w-full border-b border-[#F0F0F0] px-6 py-3 pb-4">
              <div class="flex items-end space-x-3">
                <span>Subject</span>
                <input
                  type="text"
                  class="border-0 outline-0 focus:outline-0 focus:border-0 py-0 px-0 focus:ring-0"
                  placeholder="Invitation to Job Application"
                  v-model="details.subject"
                />
              </div>
            </div>
            <div class="w-full border-b border-[#F0F0F0]">
              <QuillEditor v-model:content="details.content" contentType="html" class="bg-white" theme="snow" toolbar="minimal" />
            </div>
          </div>

          <div class="mb-3">
            <div class="flex justify-end space-x-4">
              <button @click="closeModal" class="bg-white p-3 text-brand-black rounded-lg border border-brand-black px-10 text-[14px]">Cancel</button>
              <button class="bg-brand-black p-3 text-white rounded-lg px-10 text-[14px]" @click="sendInvite">Send</button>
            </div>
          </div>
        </div>
        <!-- <div class="flex items-center justify-end space-x-4">
          <tam-button class="text-brand-black bg-brand-white rounded-xl border border-brand-black px-4" label="Cancel" @click="toggleInviteModal" />
          <tam-button class="text-white bg-brand-black hover:bg-black rounded-xl px-6" label="Send" />
        </div> -->
      </div>
    </div>
  </main>
</template>

<script setup>
import { alert } from '@/utils/helper';
import { ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const details = ref({
  email: '',
  subject: '',
  content: `Hi Cecilia,

Thank you very much for applying for the Engineering Manager position at Pixel Office.

Please be informed that we have received your application. Our hiring team is currently reviewing all applications. If you are among qualified candidates, you will receive an email notifying you of the next step soon.

Thanks again for your interest in working at our company.

Best regards,

Pixel Office`,
});

const props = defineProps({
  open: { type: Boolean },
  jobDetails: { type: Object },
  from: { type: String, default: 'talent' },
});

const emit = defineEmits(['onClose']);

const closeModal = () => {
  emit('onClose');
};

const sendInvite = async () => {
  console.log(props.from, props.talentDetails?.uuid, props.talentDetails)
  try {
    const data = {
      id: props.from === 'talent' ? props.talentDetails.uuid : props.talentDetails.candidate.uuid,
      details: details.value,
    };
    const res = await store.dispatch('global/emailTalent', data);
    if (res.status === true) {
      alert(res.message, 'success');
      closeModal();
    }
  } catch (error) {
    alert(error.response.data.message, 'error');
  }
};
</script>
